import React from "react";
import bitcoin from "../../assets/img/bitcoin.png";

import { useGetAllRates } from "../../api/rates";

const Calculator = () => {
  const { data, isLoading, error, isSuccess } = useGetAllRates();

  console.log(data);
  return (
    <section className="bg-review bg-center bg-cover bg-no-repeat h-full w-full py-[70px]">
      <div className="container mx-auto w-full h-full md:px-0 px-4">
        <div className="md:w-[60%] w-full  mx-auto bg-white shadow-md rounded-[22px] p-[20px]">
          <div className="flex items-center gap-3">
            <img
              src={bitcoin}
              className="md:h-[40px] md:w-[40px] w-[30px] h-[30px]	object-cover"
              alt="bitcoin"
            />
            <p className="py-3 text-black xl:text-[20px] text-sm">
              Bitcoin Trading Rate
            </p>
          </div>
          <div className="md:max-w-[90%] w-full mx-auto">
            <div className="mt-4 w-full flex items-center justify-between md:text-[20px] text-sm font-extrabold">
              <h3> COUNTRY</h3>
              <h3> BUY</h3>
              <h3> SELL</h3>
            </div>
            <div>
              {isLoading ? (
                <div className="py-6 w-full flex items-center justify-center">
                  <p className="py-3 text-black xl:text-base text-sm">
                    Loading...
                  </p>
                </div>
              ) : (
                <div className="mt-5">
                  {data?.data?.map((item: any, index: any) => (
                    <div
                      className="flex items-center justify-between w-full md:py-3 py-1 gap-4"
                      key={index}
                    >
                      <div className="flex items-center gap-3">
                        <img
                          src={item?.image}
                          className="md:h-[30px] md:w-[30px] h-[20px] w-[20px]	object-cover"
                          alt={item?.country}
                        />
                        <p className="py-3 text-black xl:text-base text-xs">
                          {item?.country}
                        </p>
                      </div>
                      <p className="py-3 text-black xl:text-base text-xs">
                        {item?.buyRate}
                      </p>
                      <p className="py-3 text-black xl:text-base text-xs">
                        {item?.sellRate}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
