import React from "react";

interface ReviewProps {
  review?: any;
}
const Review = () => {
  const reviews = [
    {
      id: 1,
      name: "Agofure Michael",
      title:
        "Sellfastpayfast is just the best!! Quick response and good rate! He's honest and he's one person you can trust 100 ! Trade your bitcoin now with sellfastpayfast.",
    },
    {
      id: 2,
      name: "Ayibaby",
      title:
        "Reliable, responsible, trustworthy & swift platform. I'm glad it's been working as expected, great platform, well done to the team.",
    },
    {
      id: 3,
      name: "Monalisa Sibanda",
      title:
        "Sellfastpayfast is just the best!! Service is excellent. And best platform to use Quick response and good rate! One of the best, reliable honest person I have met 100 ! Trade your bitcoin now with sellfastpayfast. Life changing platform",
    },
    {
      id: 4,
      name: "Kesten Bliss",
      title:
        "I like this app.. so fast and reliable. It's also worth trusting it because it's easy and secure. I'm so glad I came thru this app. Please you guys should keep this standard. Thanks Trustpilot.",
    },
    {
      id: 4,
      name: "Oladunjoye Emmanuel",
      title:
        "It has been great since trading BTC with the best platform (Sellfastpayfast). The unique benefits are Fast trade, secured platforms, good rates and being available everytime. I rate you more than 5stars",
    },
  ];

  const Item: React.FC<ReviewProps> = ({ review }) => {
    const firstLetter = review?.name && review?.name.charAt(0);
    return (
      <div className="w-full rounded-[16px] bg-white shadow-md border border-[#ACACAC] p-8">
        <div className="flex items-center gap-4 py-2">
          <div className="h-[50px] w-[50px] rounded-full bg-[#FEFD0C] overflow-hidden flex items-center justify-center text-black font-times border-[1.5px] border-black font-extrabold text-[20px]">
            {firstLetter}
          </div>
          <div>
            <h3 className=" text-start font-times font-semibold text-xl text-black">
              {review?.name}
            </h3>
            <p className=" text-start font-times font-medium text-sm text-gray-500 ">
              Customer
            </p>
          </div>
        </div>
        <p className=" text-start font-times font-medium md:text-base text-sm text-black/70 ">
          {review?.title}
        </p>
      </div>
    );
  };
  return (
    <section className="bg-review xl:h-full h-full bg-cover">
      <div className="w-full xl:py-[126px] pt-[99px] mx-auto xl:px-[96px] px-[16px] flex flex-col h-full overflow-hidden">
        <div className="w-full flex items-center justify-center flex-col">
          <div className="flex items-center gap-3">
            <h3 className="md:pb-2 py-3 text-start font-times font-bold md:text-[35px] text-[30px] text-black tracking-wide">
              What our customers say about us on Trust Pilot 💛
            </h3>
            {/* <AiFillHeart className="md:text-[70px] text-[30px] text-[#ff3e26] ml-3" /> */}
          </div>
        </div>
        <div className="w-full md:grid md:grid-cols-3 flex flex-col gap-[28px] my-[62px]">
          {reviews.map((review, index) => (
            <Item key={review.id} review={review} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Review;
