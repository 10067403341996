import React from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { AppButton } from "../common/Buttons";
import bitcoin from "../../assets/img/bitcoin.png";
import usdt from "../../assets/img/tether.png";
import ethereum from "../../assets/img/ethereum.png";
import flag from "../../assets/img/united-states.png";

const data = [
  {
    id: 1,
    text: "BTC",
    img: bitcoin,
  },
  {
    id: 2,
    text: "USDT",
    img: usdt,
  },
  {
    id: 3,
    text: "ETHEREUM",
    img: ethereum,
  },
  {
    id: 4,
    text: "USD",
    img: flag,
  },
];

const Hero = () => {
  return (
    <section className="bg-[#FEFD0C] xl:h-full parallax h-full bg-cover">
      <div className="w-full xl:pt-[146px] pt-[99px] mx-auto xl:px-[96px] px-[16px] flex flex-col xl:flex-row h-full overflow-hidden">
        <div className="xl:w-[50%] w-full h-full xl:pb-0 pb-[150px]">
          <div>
            <h3 className="tracking-wide xl:text-[4rem] text-[44px] leading-[150%] text-black xl:w-[60rem] w-full font-semibold">
              Fast and Trusted
            </h3>
            <p className="text-black xl:text-base font-normal text-base font-times xl:w-[25.3rem] xl:pb-[1rem] py-[2rem] leading-[30px] tracking-wide">
              The most trustworthy cryptocurrency exchange platform available.
              Where you can have quick access to your money whenever you desire.
            </p>
            <p className="text-black/70 xl:text-base font-bold text-base font-times xl:w-[25.3rem] xl:pb-[1.3rem] pb-[2rem] leading-[150%]">
              Supports 30+
            </p>
            <div className="pb-9 flex items-center gap-3">
              {data?.map((item, index) => (
                <div
                  className="h-[40px] w-[40px] rounded-full overflow-hidden bg-white"
                  key={index}
                >
                  <img
                    src={item.img}
                    alt={item.text}
                    className="h-full w-full object-cover"
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center xl:gap-6 gap-4">
              <AppButton
                icon={FaGooglePlay}
                label="Get it on"
                placeholder="Google Play"
                href="https://play.google.com/store/apps/details?id=com.brandboxafrica.sellfast&hl=en&gl=US&pli=1"
              />
              <AppButton
                icon={FaApple}
                label="Download on the"
                placeholder="App store"
                href="https://apps.apple.com/us/app/sellfastpayfast/id1525149238"
              />
            </div>
            <div className="rounded-[200px] border border-[#FEFD0C] p-[14px] w-[180px] flex items-center justify-center cursor-pointer mt-6">
              <div className="flex items-center">
                <h3 className="text-[#FEFD0C] text-base font-medium font-times ml-4 mr-[28px]">
                  Let's trade
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:w-[50%] h-full">
          <img
            src="https://ik.imagekit.io/shiga/sfpf/pngimg%201.png?updatedAt=1713648296070"
            className="w-full h-[422px] object-cover"
            alt="Hero"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
